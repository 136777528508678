import "./chat-with-us.scss"

import loadable from "@loadable/component"

import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import { InlineWidget } from "react-calendly"

// import Button from "../components/Button"
import Dash from "../components/Dash"
import Layout from "../components/Layout"
import { getAllLocales, getCurrentLanguagePage } from "../plugins/common"

const Pros = loadable(() => import("../components/homepage/Pros"))
const Process = loadable(() => import("../components/homepage/Process"))
const Partners = loadable(() => import("../components/homepage/Partners"))
const Testimonial = loadable(() => import("../components/homepage/Testimonial"))
const simplePageShape_left = "../images/simplePage/simplePageShape_left.webp"
const simplePageShape_right = "../images/simplePage/simplePageShape_right.webp"

const ChatWithUsPage = ({ pageContext, location }) => {
  const { data, general, form } = pageContext
  const { title, subtitle, choices, meta, localizations, locale } = data

  const aleaToken = general.aleaToken
  const homepageData = pageContext.homepage[0]
  const generalData = general.allStrapiGeneral.nodes[0]

  const choice = choices[0]

  // const [choice, setChoice] = useState(choices[0])

  // const isSelected = name => name === choice.title

  // const proceed = () => initInlineWidget({ url: choice.url })

  //   console.log(meta)
  // console.log("common = ", choices[0])
  const languageOptions = general.allStrapiLanguages?.nodes ?? "en"
  const lang = getCurrentLanguagePage(location, languageOptions)
  const enableFooterForm = data.footerForm?.enableFooter
  const footerForm = {
    ...data.footerForm,
    ...form.freeQuote,
  }

  return (
    <Layout
      props={general}
      meta={meta}
      localizations={getAllLocales(localizations, locale)}
      location={location}
      lang={lang}
      hideNewsletter={true}
      token={aleaToken}
      footerForm={enableFooterForm ? footerForm : null}
    >
      <div className="chatWithUs">
        <div className="container-fluid bg-daffodil text-center header position-relative">
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="left position-absolute"
            src={simplePageShape_left}
            alt=""
            layout="constrained"
          />
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className="right position-absolute"
            src={simplePageShape_right}
            alt=""
            layout="constrained"
          />
          <h1 className="ming">{title}</h1>
        </div>
        <div className="container content card d-flex soft-shadows">
          <div className="row justify-content-center">
            <div className="subtitleArea col-lg-12 col-12">
              <h2 className="subtitle ming">{subtitle}</h2>
              <Dash />
            </div>

            {/* <div className="col-lg-9 col-12 row radioCustom">
              {choices.map((item, index) => {
                return (
                  <div
                    role="button"
                    tabIndex={0}
                    key={index}
                    className={`col-lg-6 col-12 box text-center ${
                      isSelected(item.title)
                        ? "bg-daffodil choice"
                        : "unselect-choice"
                    }`}
                    onClick={() => setChoice(item)}
                    onKeyDown={() => setChoice(item)}
                  >
                    <label className="form-check-label semiBold">
                      {isSelected(item.title) && (
                        <StaticImage
                          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                          className="checked"
                          src="../images/get-a-quote/checked.webp"
                          alt="checked"
                          width={25}
                        />
                      )}
                      <GatsbyImage
                        sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                        image={getGatsbyImage(item.image)}
                        alt={item.title}
                        width={100}
                      />
                      <div className="lora-Bold ming title">{item.title}</div>
                      <div>{item.summary}</div>
                    </label>
                  </div>
                )
              })}
            </div> */}
          </div>

          <InlineWidget url={choice.url} />
          {/* <div className="row buttonRow">
            <div
              role="button"
              tabIndex={-1}
              className="text-lg-end text-center"
              onClick={() => proceed()}
              onKeyDown={() => proceed()}
            >
              <Button lang={lang} type="primary" text={buttonLabel} />
            </div>
          </div> */}
        </div>
      </div>
      <div className={`overflow-hidden`}>
        <Pros
          props={generalData.sellingPoints}
          lang={lang}
          additionalClass={"nonHomepage"}
        />
        <Process
          props={generalData.process}
          lang={lang}
          additionalClass={"nonHomepage"}
        />
        <Partners props={homepageData.partners} lang={lang} />
        <Testimonial props={homepageData.testimony} lang={lang} />
      </div>
    </Layout>
  )
}
export default ChatWithUsPage
